import { Controller } from "stimulus";

export default class extends Controller {
  // this is a child component to checkout cart controller. When the cart totals are loaded in triggers the checkAllQty function
  static targets = ["submitBtn", "submitBtnMobile", "tooltip", "tooltipMobile"];

  allowSubmit() {
    this.submitBtnTarget.disabled = false;
    this.submitBtnTarget.style.pointerEvents = "auto";
    this.submitBtnMobileTarget.disabled = false;
    this.submitBtnMobileTarget.style.pointerEvents = "auto";
    this.tooltipTarget.dataset.title = "";
    this.tooltipTarget.dataset.originalTitle = "";
    this.tooltipMobileTarget.dataset.title = "";
    this.tooltipMobileTarget.dataset.originalTitle = "";
  }

  preventSubmit() {
    this.submitBtnTarget.disabled = true;
    this.submitBtnTarget.style.pointerEvents = "none";
    this.submitBtnMobileTarget.disabled = true;
    this.submitBtnMobileTarget.style.pointerEvents = "none";
    this.tooltipTarget.dataset.originalTitle = "Please save billing information before placing order";
    this.tooltipMobileTarget.dataset.originalTitle = "Please save billing information before placing order";
  }

  submit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.submitBtnTarget.innerHTML = "Submitting Order";
    this.submitBtnTarget.disabled = true;
    this.submitBtnMobileTarget.innerHTML = "Submitting Order";
    this.submitBtnMobileTarget.disabled = true;

    const submitBtn = document.getElementById("billing_info_form");
    submitBtn.click();
  }
}
