import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["optionsContainer"];

  optionsUpdated(e) {
    e.preventDefault();
    const shipmentId = e.detail.shipmentId;
    //console.log("Shipping Options: updated() id: ", shipmentId);
    // TODO: Hide shipping options (for correct shipment) while new options are retrieved.
  }
}
