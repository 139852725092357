// This controller is used for analytics event tracking for PostHog.
// NOTE 'Add To Cart' event is tracked outside stimulus in the jQuery function analyticsTrackAddToCart()
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["note", "revisionText", "revisionImg"];

  static values = {
    cartData: Object,
  };

  trackCartApprove() {
    this.cartDataValue["products"].forEach(function (product) {
      if (typeof posthog !== "undefined") {
        posthog.capture("Product Purchased", product);
      }
      if (typeof userpilot !== "undefined") {
        userpilot.track("Product Purchased", product);
      }
    });

    this.cartDataValue["shipments"].forEach(function (shipment) {
      if (typeof posthog !== "undefined") {
        posthog.capture("Shipment", shipment);
      }
      if (typeof userpilot !== "undefined") {
        userpilot.track("Shipment", shipment);
      }
    });

    if (typeof posthog !== "undefined") {
      posthog.capture("Approve Order", {
        cart_total_cost: this.cartDataValue["total_cost"],
        cart_total_qty: this.cartDataValue["total_qty"],
        total_shipments: this.cartDataValue["shipments"].length,
        total_products: this.cartDataValue["products"].length,
      });
    }
    if (typeof userpilot !== "undefined") {
      userpilot.track("Approve Order", {
        cart_total_cost: this.cartDataValue["total_cost"],
        cart_total_qty: this.cartDataValue["total_qty"],
        total_shipments: this.cartDataValue["shipments"].length,
        total_products: this.cartDataValue["products"].length,
      });
    }
    if (gtag !== "undefined") {
      const totalValue = this.cartDataValue["total_cost"];
      const itemNumbers = this.cartDataValue.products?.reduce((itemsJSON, product, pidx) => {
        itemsJSON[pidx] = product?.item_number;
        return itemsJSON;
      }, {});
      const totalQuantity = this.cartDataValue.total_qty;
      const clientType = this.cartDataValue.client_type;
      const transactionId = this.cartDataValue.transaction_id;

      gtag("event", "purchase", {
        total_value: totalValue,
        transaction_id: transactionId,
        item_numbers: itemNumbers,
        total_quantity: totalQuantity,
        client_type: clientType,
      });
    }
  }

  trackRevisionSent() {
    if (typeof posthog !== "undefined") {
      posthog.capture("Revision Requested", {
        message_content: this.revisionTextTarget.value,
        image_value: this.revisionImgTarget.value,
      });
    }
    if (typeof userpilot !== "undefined") {
      userpilot.track("Revision Requested", {
        message_content: this.revisionTextTarget.value,
        image_value: this.revisionImgTarget.value,
      });
    }
  }

  trackSampleRequest() {
    if (typeof posthog !== "undefined") {
      posthog.capture("Sample Requested");
    }
    if (typeof userpilot !== "undefined") {
      userpilot.track("Sample Requested");
    }
  }

  // NOTE the below is captured in JS when creating the new estimate search `create new estimate`
  // kept here for reference, but this function is not currentlly used.
  trackQuoteCreated() {
    if (typeof posthog !== "undefined") {
      posthog.capture("Client Created Quote");
    }
    if (typeof userpilot !== "undefined") {
      userpilot.track("Client Created Quote");
    }
  }

  trackColorChange() {
    if (typeof posthog !== "undefined") {
      posthog.capture("Client Changed Colors");
    }
    if (typeof userpilot !== "undefined") {
      userpilot.track("Client Changed Colors");
    }
  }

  trackKnittedToolDesign() {
    if (typeof posthog !== "undefined") {
      posthog.capture("Knitted Tool Design Created");
    }
    if (typeof userpilot !== "undefined") {
      userpilot.track("Knitted Tool Design Created");
    }
  }

  trackSignUp() {
    if (typeof posthog !== "undefined") {
      posthog.capture("New User Signed Up");
    }
    if (typeof userpilot !== "undefined") {
      userpilot.track("New User Signed Up");
    }
  }

  reset() {
    if (typeof posthog !== "undefined") {
      posthog.reset();
    }
  }
}
