import { Controller } from "stimulus";

export default class extends Controller {
  // this is a child component to checkout cart controller. When the cart totals are loaded in triggers the checkAllQty function
  static targets = [
    "email",
    "saveBtn",
    "addressEntry",
    "editEmail",
    "editLabel",
    "editBtn",
    "form",
    "updateEmail",
    "showEmail",
    "updateBtn",
    "saveUpdate",
    "returnBilling",
    "submit",
    "companyName",
    "street",
    "city",
    "state",
    "zipCode",
    "formErrors",
    "confirmationForm",
  ];

  save(e) {
    e.preventDefault();
    e.stopPropagation();

    const email = this.emailTarget.value;
    let errorMessages = [];

    if (!email) {
      errorMessages.push("Email is required");
    }
    if (!this.isValidEmail(email)) {
      errorMessages.push("Please enter a valid email address");
    }

    if (errorMessages.length > 0) {
      this.formErrorsTarget.innerHTML = '<div class="alert alert-danger" role="alert">' + errorMessages.join("<br> ") + "</div>";
      return;
    } else {
      this.formErrorsTarget.innerHTML = "";
      this.confirmationFormTarget.classList.add("d-none");
      this.formTarget.classList.add("d-none");
      this.editEmailTarget.classList.remove("d-none");
      this.editLabelTarget.innerHTML = email;

      // triggers an event in the parent
      const event = new Event("submit-order", { bubbles: true });
      this.element.dispatchEvent(event);
    }
  }

  edit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.confirmationFormTarget.classList.remove("d-none");
    this.formTarget.classList.remove("d-none");
    this.editEmailTarget.classList.add("d-none");

    // triggers an event in the parent
    const event = new Event("prevent-submit", { bubbles: true });
    this.element.dispatchEvent(event);
  }

  showUpdate(e) {
    e.preventDefault();
    e.stopPropagation();

    this.showEmailTarget.classList.add("d-none");
    this.updateEmailTarget.classList.remove("d-none");
    this.returnBillingTarget.classList.remove("d-none");
  }

  hideUpdate(e) {
    e.preventDefault();
    e.stopPropagation();

    this.returnBillingTarget.classList.add("d-none");
    this.showEmailTarget.classList.remove("d-none");
    this.updateEmailTarget.classList.add("d-none");
  }

  update(e) {
    e.preventDefault();
    e.stopPropagation();

    const email = this.emailTarget.value;

    let errorMessages = [];
    if (!email) {
      errorMessages.push("Email is required");
    }
    if (!this.isValidEmail(email)) {
      errorMessages.push("Please enter a valid email address");
    }

    if (errorMessages.length > 0) {
      this.formErrorsTarget.innerHTML = '<div class="alert alert-danger" role="alert">' + errorMessages.join("<br> ") + "</div>";
      return;
    } else {
      this.formErrorsTarget.innerHTML = "";
      this.submitTarget.click();
      this.saveUpdateTarget.innerHTML = "Saving";
      this.saveUpdateTarget.disabled = true;
    }
  }

  isValidEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }
}
