import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["thumbColor", "wikForm", "colorContainer", "thumbChangeNotice"];

  useThumbColor(e) {
    this.thumbColorTarget.value = e.target.value;
    this.colorContainerTarget.classList.add("d-none");
    this.thumbChangeNoticeTarget.classList.remove("d-none");
    this.wikFormTarget.submit();
  }

  clearThumbColor() {
    this.thumbColorTarget.value = "";
  }
}
