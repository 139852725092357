import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["assetUrl", "buttonText"];

  copyLink() {
    const assetUrl = this.assetUrlTarget.value;

    if (assetUrl) {
      navigator.clipboard
        .writeText(assetUrl)
        .then(() => {
          this.changeButtonText();
        })
        .catch((error) => {
          console.error("Copying failed:", error);
        });
    } else {
      console.log("Asset URL is empty. Nothing to copy.");
    }
  }

  changeButtonText() {
    this.buttonTextTarget.textContent = "Copied!";
  }
}
