import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["priceDropdown"];
  value = {
    discount: false,
  };

  showHidePriceSet(e) {
    const prodVersion = this.getNameVersion(e.target.id);

    const targetElem = this.priceDropdownTargets.find((elem) => {
      const elemId = this.getNameVersion(elem.id);
      if (elemId === prodVersion) {
        return elem;
      }
    });

    if ($(targetElem).is(":visible")) {
      $(targetElem).addClass("d-none");
    } else {
      $(targetElem).removeClass("d-none");
    }
  }

  updateQuantity(e) {
    const id = $(e.target).closest("tbody")[0].id;
    const prodVersion = this.getNameVersion(id);

    const elem = $(e.target);
    if (elem.is("th")) {
      const quantity = elem.parent().children()[0].innerHTML;
      $(`#cart_total_${prodVersion}`).val(quantity);
      $(`#ci_quantity_${prodVersion}`).click();
    }
  }

  // helper functions
  getNameVersion(id) {
    const splitId = id.split("_");
    const prod = splitId[splitId.length - 2];
    const version = splitId[splitId.length - 1];
    return `${prod}_${version}`;
  }
}
