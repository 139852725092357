import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["addressForm", "addressDisplay", "editBtn", "returnAddressBtn"];

  connect() {
    //const addressCount = document.querySelectorAll('[data-edit-address-target="addressDisplay"]').length;

    //if (addressCount && addressCount === 8 ) {
    //  this.hideDestinationBtn(addressCount)
    //}
    this.removeWarning();
  }

  removeWarning() {
    const shippingWarningDiv = document.getElementById("shipping-limit-warning");
    const shippingOptions = document.getElementById("shipping-options");
    if (shippingOptions != null && shippingWarningDiv != null) {
      shippingWarningDiv.remove();
    }
  }

  hideDestinationBtn() {
    const cartShippingControls = document.getElementById("cartShippingControls");
    const shippingBtns = document.getElementById("destination_shipping_btns");
    const shippingDestinationBtn = document.getElementById("shipping-destination-btn");
    const shippingWarning = document.getElementById("shipping-limit-warning");
    const btnContainer = document.getElementById("destination-container");

    if (shippingDestinationBtn) {
      shippingDestinationBtn.remove();
      shippingBtns.classList.remove("row");
      shippingBtns.classList.add("d-flex", "flex-row", "justify-content-center");

      btnContainer.classList.add("d-flex", "flex-row", "justify-content-center");
    }

    if (!shippingWarning) {
      let shippingLimitWarning = document.createElement("div");
      shippingLimitWarning.id = "shipping-limit-warning";

      shippingLimitWarning.innerText =
        "Only 8 shipments allowed. For additional shipments, please reach out to your Sales Manager or call 800-618-4861 for help";
      shippingLimitWarning.classList.add("bg-light", "p-2", "mt-4", "mb-2");
      cartShippingControls.appendChild(shippingLimitWarning);
    }
  }

  hideEdit(e) {
    e.preventDefault()
    e.stopPropagation()
    this.editBtnTarget.classList.remove("d-none");
    // this.returnAddressBtnTarget.classList.add("d-none");
    this.addressDisplayTarget.classList.remove("d-none");
    this.addressFormTarget.classList.add("d-none");

    // triggers an event in checkout cart to hide "Add Another destination" and "Shipping methods" button
    const event = new Event("hide-address", { bubbles: true });
    this.element.dispatchEvent(event);
  }

  showAddress() {
    this.addressDisplayTarget.classList.add("d-none");
    this.addressFormTarget.classList.remove("d-none");
    this.editBtnTarget.classList.add("d-none");
    // this.returnAddressBtnTarget.classList.remove("d-none");

    // triggers an event in checkout cart to hide "Add Another destination" and "Shipping methods" button
    const event = new Event("edit-address", { bubbles: true });
    this.element.dispatchEvent(event);
  }
}
