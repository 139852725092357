import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["open", "close"];

  connect() {
    if (sessionStorage.getItem("closeSideNav") === "true") this.close();
  }

  open(e) {
    var sideNav = document.getElementById("side_nav");
    sessionStorage.setItem("closeSideNav", "false");
    sideNav.style.transition = sessionStorage.getItem("closeSideNav") === "false" ? "0.5s" : "0s";
    sideNav.style.width = "16rem";
    document.getElementById("side-nav-right-content").style.marginLeft = "20rem";
    document.querySelector(".close-side-nav").style.display = "block";
    document.querySelector(".open-side-nav").style.display = "none";
    setTimeout(function () {
      document.getElementById("side-nav-name").style.display = "block";
    }, 250);
    setTimeout(function () {
      document.getElementById("side-nav-contact-sales").style.display = "block";
    }, 350);
    var iconTitle = document.getElementsByClassName("side-nav-icon-title");
    iconTitle.forEach((title) =>
      setTimeout(function () {
        title.style.display = "contents";
      }, 230)
    );
    var icons = document.getElementsByClassName("list-group-item");
    icons.forEach((icon) => (icon.style.textAlign = "initial"));
  }

  close(e) {
    if (document.getElementById("projectDropdown")) document.getElementById("projectDropdown").classList.remove("show");
    var sideNav = document.getElementById("side_nav");
    var sideNavRight = document.getElementById("side-nav-right-content");
    sideNav.style.transition = sessionStorage.getItem("closeSideNav") === "true" ? "0s" : "0.5s";
    sideNavRight.style.transition = sessionStorage.getItem("closeSideNav") === "true" ? "0s" : "0.5s";
    sideNavRight.style.marginLeft = "9rem";
    sideNav.style.width = "5.5rem";
    sessionStorage.setItem("closeSideNav", "true");
    document.getElementById("side-nav-contact-sales").style.display = "none";
    document.getElementById("side-nav-name").style.display = "none";
    document.querySelector(".close-side-nav").style.display = "none";
    document.querySelector(".open-side-nav").style.display = "block";
    var iconTitle = document.getElementsByClassName("side-nav-icon-title");
    iconTitle.forEach((title) => (title.style.display = "none"));
    var icons = document.getElementsByClassName("list-group-item");
    icons.forEach((icon) => (icon.style.textAlign = "center"));
  }
}
