import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["amountSelect", "getTransferAmountsBtn"];
  static values = {
    url: String,
  };

  changeSupplier(e) {
    this.getTransferAmountsBtnTarget.click();
  }

  changeReceiver(e) {
    this.getTransferAmountsBtnTarget.click();
  }
}
