import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "name",
    "company",
    "street",
    "street2",
    "city",
    "state",
    "zip_code",
    "country",
    "email",
    "phone",
    "vatNumber",
    "skipValidation",
    "errors",
    "submit",
    "administrative_area",
    "address_origin"
  ];

  // this could be deleted
  fieldUpdate() {
    const allTargets = [
      this.nameTarget,
      this.companyTarget,
      this.streetTarget,
      this.street2Target,
      this.cityTarget,
      this.zip_codeTarget,
      this.emailTarget,
      this.phoneTarget,
      this.vatNumberTarget,
      this.stateTarget,
      this.countryTarget,
    ];

    const updatedAddress = [];

    allTargets.forEach((target) => {
      target;
      let state;
      let value;

      if (target == this.countryTarget) {
        state = this.countryValue;
        value = target.value;
        this.checkInternational(value);
      } else if (target == this.stateTarget) {
        state = this.stateValue;
        value = target.value;
      } else {
        state = target.getAttribute("value");
        const val = target.value;
        value = val ? val : state === undefined ? undefined : "";
      }

      if (state != value && !updatedAddress.includes(target.id)) {
        updatedAddress.push(target.id);
      }
    });
  }

  checkInternational(country) {
    const treatedAsDomestic = ["", "United States", "TBD"]
    if (treatedAsDomestic.includes(country)) {
      this.vatNumberTarget.value = "";
      this.vatNumberTarget.classList.add("d-none");
      this.phoneTarget.value = "";
      this.phoneTarget.classList.add("d-none");

      this.handleStateAdminTargets(true)

      this.administrative_areaTarget.value = "";
      this.administrative_areaTarget.classList.add("d-none");
      this.stateTarget.classList.remove("d-none");
    } else if (country == "Canada" || country == "Australia") {
      //this.vatNumberTarget.value = "";
      this.vatNumberTarget.classList.remove("d-none");

      this.handleStateAdminTargets(true)

      this.administrative_areaTarget.value = "";
      this.administrative_areaTarget.classList.add("d-none");
      this.stateTarget.classList.remove("d-none");
      this.phoneTarget.classList.remove("d-none")

    } else {
      this.handleStateAdminTargets(false)
      this.vatNumberTarget.classList.remove("d-none");
      this.phoneTarget.classList.remove("d-none");
    }
  }

  handleStateAdminTargets(stateNeeded) {
    if (stateNeeded) {
      this.administrative_areaTarget.value = ""
      this.administrative_areaTarget.classList.add("d-none")
      this.stateTarget.classList.remove("d-none")
    } else {
      this.stateTarget.value = ""
      this.stateTarget.classList.add("d-none")
      this.administrative_areaTarget.classList.remove("d-none")
    }
  }

  checkIfStateNeeded(country) {
    const countriesWithStates = ["", "United States", "TBD", "Canada"]
    countriesWithStates.includes(country)
  }

  errorUpdate() {
    this.submitTarget.value = "Update";
    this.submitTarget.disabled = false;

    this.skipValidationTarget.checked = true;
  }

  countryUpdate(e) {
    this.checkInternational(e.target.value)
  }

  connect() {
    this.stateValue = this.stateTarget.value;
    this.countryValue = this.countryTarget.value;

    this.checkInternational(this.countryValue);
  }
}
