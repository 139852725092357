import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["bubble", "chat"];

  connect() {
    this.hideChat();
  }

  displayChat() {
    this.bubbleTarget.classList.add("d-none");
    this.chatTarget.classList.remove("d-none");
  }

  hideChat() {
    this.chatTarget.classList.add("d-none");
    this.bubbleTarget.classList.remove("d-none");
  }
}
