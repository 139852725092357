import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["hideAcctCostModal", "submitBtn"];

  connect() {
    this.hideAcctCostModalTarget.click();
    if ($("body").hasClass("modal-open")) {
      $("body").removeClass("modal-open");
      $("body").css("padding-right", "0px");
      $(".modal-backdrop").remove();
    }
  }
}
