import { Controller } from "stimulus";

export default class extends Controller {
  // this is a child component to checkout cart controller. When the cart totals are loaded in triggers the checkAllQty function
  static targets = ["submitBtn"];

  showSubmit() {
    this.submitBtnTarget.classList.remove("d-none");
    this.submitBtnTarget.disabled = false;
  }

  hideSubmit() {
    this.submitBtnTarget.classList.add("d-none");
    this.submitBtnTarget.disabled = true;
  }
  
  connect() {
    if ($("body").hasClass("modal-open")) {
      $("body").removeClass("modal-open");
      $("body").css("padding-right", "0px");
      $(".modal-backdrop").remove();
    }
  }
}
