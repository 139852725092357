import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["showQty", "editQty", "quantity", "qtyTable", "submitBtn"];

  showPrice = true;

  showHidePriceSet(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.showPrice) {
      this.editQtyTarget.classList.add("d-flex");
      this.editQtyTarget.classList.add("flex-row");
      this.editQtyTarget.classList.remove("d-none");
      this.showQtyTarget.classList.add("d-none");
      this.qtyTableTarget.classList.remove("d-none");
      this.showPrice = false;
    } else {
      this.editQtyTarget.classList.remove("d-flex");
      this.editQtyTarget.classList.remove("flex-row");
      this.editQtyTarget.classList.add("d-none");
      this.showQtyTarget.classList.remove("d-none");
      this.qtyTableTarget.classList.add("d-none");
      this.showPrice = true;
    }
  }

  updateQuantity(e) {
    if ($(e.target).is("th")) {
      const quantity = $(e.target).parent().children()[0].innerHTML;
      this.quantityTarget.value = quantity;
      this.submitBtnTarget.click();
    }
  }
}
