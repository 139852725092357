import { Controller } from "stimulus";

export default class extends Controller {
  // this is a child component to checkout cart controller. When the side cart totals are updated, it updates a hidden div that holds the status.
  // when this loads, hide/show addresses
  connect() {
    // triggers an event in the parent
    const event = new Event("status-check", { bubbles: true });
    this.element.dispatchEvent(event);
  }
}
