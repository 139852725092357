import { Controller } from "stimulus";

export default class extends Controller {
  // this is a child component to checkout cart controller. When this component loads it adds an event listener to update the new address submit btn
  connect() {
    // add an event listener to the component
    const event = new Event("update-submit-address", { bubbles: true });
    this.element.dispatchEvent(event);
  }
}
