import { Controller } from "stimulus";

let suggestionMap = {};

let timer;

export default class extends Controller {
    static targets = [
        "search",
        "streetLine",
        "secondary",
        "state",
        "city",
        "zipcode",
        "suggestionSelect",
        "country",
        "administrative_area"
    ];

    static values = {
        states: Object
    }

    async logKeystroke() {
        clearTimeout(timer);
        timer = setTimeout(async () => {
            suggestionMap = {};
            this.suggestionSelectTarget.innerText = "";
            this.suggestionSelectTarget.classList.remove("d-none")

            if (!this.countryTarget.value) { this.countryTarget.value = "United States" } 

            const host = document.querySelector('meta[name="RELATIVE_URL_ROOT"]').content

            if (this.searchTarget.value.trim() !== "") {
                const res = await fetch(`${host}/address/send_autocomplete_lookup`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        address: {
                            search_term: this.searchTarget.value,
                            country: this.countryTarget.value || "US"
                        }
                    })
                })
    
                let suggestions = await res.json()
                suggestions = suggestions.suggestions // bruh
                
                this.suggestionSelectTarget.classList.remove("d-none")
    
                this.setOptions(suggestions)
                
                this.suggestionSelectTarget.size = 5
            }
            
        }, 250)
    }

    setOptions(suggestions) {
        if (suggestions.length === 0) {
            const option = document.createElement('li')
            option.innerText = "No Results Found"
            option.classList = 'my-1 mx-4 p-2 rounded-3'
            this.suggestionSelectTarget.appendChild(option)
            return
        }

        suggestions.forEach((suggestion, idx) => {
            const optionVal = suggestion["address_text"] ? suggestion["address_id"] : idx
            const sanitizedSuggestion = this.sanitizeSuggestion(suggestion)
            const option = document.createElement('li')
            option.id = optionVal
            option.value = idx
            option.tabIndex = 1
            option.innerText = suggestion.address_text ? suggestion.address_text : sanitizedSuggestion
            option.classList = 'my-1 mx-4 p-2 rounded-3 smarty_option'
            this.addOptionListeners(option)
            this.suggestionSelectTarget.appendChild(option)
            suggestionMap[idx] = suggestion
        })
    }

    focusNextOption(e, direction) {
        // prevent main body scroll
        e.preventDefault()
        e.stopPropagation()
        let optionIds = []
        const activeOption = document.activeElement.id
        const options = document.querySelectorAll("[id*=smarty_option_]")
        options.forEach(option => optionIds.push(option.id))
        const currentActiveIndex = optionIds.indexOf(activeOption)
        if (direction === 'ArrowDown') {
            const currentNotLast = currentActiveIndex < optionIds.length - 1;
            if (currentNotLast) {
                const nextOption = optionIds[currentActiveIndex + 1]
                document.querySelector(`#${nextOption}`).focus();
            }
        } else if (direction === 'ArrowUp') {
            const currentNotFirst = currentActiveIndex > 0
            if (currentNotFirst) {
                const nextOption = optionIds[currentActiveIndex - 1]
                document.querySelector(`#${nextOption}`).focus();
            }
        }
    }

    addOptionListeners(option) {
        this.addKeyDownListener(option)
        this.addClickListener(option)
        this.addMouseoverListener(option)
    }

    addKeyDownListener(option) {
        option.addEventListener('keydown', (e) => {
            switch (e.code) {
                case 'Enter':
                    this.populateAddress(e)
                    return
                case 'ArrowDown':
                    this.focusNextOption(e, 'ArrowDown')
                    return;
                case 'ArrowUp':
                    this.focusNextOption(e, 'ArrowUp')
                    return;
                case 'Escape':
                    this.clearLookup()
                    return;
                default:
                    return
            }
        })
    }

    addClickListener(option) {
        option.addEventListener("click", (e) => {
            this.populateAddress(e)
        })
    }

    addMouseoverListener(option) {
        option.addEventListener("mouseover", (e) => {
            e.target.focus()
        })
    }

    clearLookup() {
        this.suggestionSelectTarget.innerHTML = ""
        this.suggestionSelectTarget.classList.add('d-none')
    }

    async populateAddress(e) {
        this.suggestionSelectTarget.classList.add("d-none")
        this.searchTarget.value = ""
        const addr = suggestionMap[e.target.value]
        const hasStatesOrIsPseudoState = ["United States", "Puerto Rico"]
        if (hasStatesOrIsPseudoState.includes(this.countryTarget.value)) {
            this.streetLineTarget.value = addr.street_line
            this.secondaryTarget.value = addr.secondary
            this.stateTarget.value = addr.state
            this.cityTarget.value = addr.city
            this.zipcodeTarget.value = addr.zipcode
        } else {
            // for international lookups in Smarty's V2 API,
            // we send the initial request to get initial address suggestions - this still lives inside logKeyStroke
            // _Here_, we need to circle around and send another request for the selected address
            // This will loop/recurse inside the SmartyAutocomplete wrapper until we get an actual address value back
            const host = document.querySelector('meta[name="RELATIVE_URL_ROOT"]').content

            const res = await fetch(`${host}/address/send_autocomplete_lookup`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    address: {
                        search_term: undefined,
                        country: this.countryTarget.value || "US",
                        address_id: e.target.id
                    }
                })
            })

            const addr = await res.json()
            this.streetLineTarget.value = addr.suggestions.street
            this.administrative_areaTarget.value = addr.suggestions.administrative_area
            this.cityTarget.value = addr.suggestions.locality
            this.zipcodeTarget.value = addr.suggestions.postal_code
            this.secondaryTarget.value = ""
            if (["Canada", "Australia"].includes(this.countryTarget.value)) {
                this.streetLineTarget.value = this.sanitizeStreet(this.countryTarget.value, addr.suggestions.street)
                this.stateTarget.value = this.sanitizeState(this.countryTarget.value, addr.suggestions.administrative_area)
            }
        }
    }

    sanitizeState(country, state) {
        switch (country) {
            case 'Canada':
                return Object.keys(this.statesValue).find(key => this.statesValue[key] == state)
            case 'Australia':
                if (state == "WA") {
                    return "Western Australia"
                } else {
                    return Object.keys(this.statesValue).find(key => this.statesValue[key] == state)
                }
        }
    }

    sanitizeStreet(country, street) {
        // regex patterns are to check if the address starts with a unit number or not
        // if so, strip it out
        switch (country) {
            case 'Australia':
                // AUS Unit #s => U 2 123 Wherever St, U 2/12 Wherever St
                const ausPattern = /^U\s(\d+\/?\d*\s?)/
                return ausPattern.test(street) ? street.split(" ").slice(2, street.split(" ").length).join(" ") : street
            case 'Canada':
                // CAN Unit #s => A-123 Whever St, 122-123 Wherever St
                const canPattern = /^\d+-|^[A-Z]-/
                return canPattern.test(street) ? street.split("-")[1] : street
        }
    }

    sanitizeSuggestion(suggestion) {
        return `${suggestion.street_line}${suggestion.secondary ? ' ' + suggestion.secondary : ''}, ${suggestion.city} ${suggestion.state}, ${suggestion.zipcode}`
    }
}