import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["showHideLineItemBtn", "showHideLineItems", "downIcon", "upIcon"];

  showHide() {
    if (this.showHideLineItemsTarget.classList.contains("d-none")) {
      this.showHideLineItemsTarget.classList.remove("d-none");
      this.downIconTarget.classList.add("d-none");
      this.upIconTarget.classList.remove("d-none");
    } else {
      this.showHideLineItemsTarget.classList.add("d-none");
      this.downIconTarget.classList.remove("d-none");
      this.upIconTarget.classList.add("d-none");
    }
  }
}
