import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "name",
    "company",
    "street",
    "street2",
    "city",
    "state",
    "zip_code",
    "country",
    "email",
    "phone",
    "vatNumber",
    "skipValidation",
    "submitAddressBtn",
    "administrative_area",
  ];

  countryUpdate() {
    this.checkInternational(this.countryTarget.value);
  }

  checkInternational(country) {
    const treatedAsDomestic = ["United States", "TBD", ""]

    if (treatedAsDomestic.includes(country)) {
      this.vatNumberTarget.value = "";
      this.vatNumberTarget.classList.add("d-none");
      this.phoneTarget.value = "";
      this.phoneTarget.classList.add("d-none");

      this.handleStateAdminTargets(true)

      this.administrative_areaTarget.value = "";
      this.administrative_areaTarget.classList.add("d-none");
      this.stateTarget.classList.remove("d-none");
    } else if (country == "Canada" || country == "Australia") {
      //this.vatNumberTarget.value = "";
      this.vatNumberTarget.classList.remove("d-none");

      this.handleStateAdminTargets(true)

      this.administrative_areaTarget.value = "";
      this.administrative_areaTarget.classList.add("d-none");
      this.stateTarget.classList.remove("d-none");
      this.phoneTarget.classList.remove("d-none")
    } else {
      this.handleStateAdminTargets(false)
      this.vatNumberTarget.classList.remove("d-none");
      this.phoneTarget.classList.remove("d-none");
    }
  }

  handleStateAdminTargets(stateNeeded) {
    if (stateNeeded) {
      this.administrative_areaTarget.value = ""
      this.administrative_areaTarget.classList.add("d-none")
      this.stateTarget.classList.remove("d-none")
    } else {
      this.stateTarget.value = ""
      this.stateTarget.classList.add("d-none")
      this.administrative_areaTarget.classList.remove("d-none")
    }
  }

  tbd(e) {
    const checkTargets = [
      this.nameTarget,
      this.companyTarget,
      this.streetTarget,
      this.street2Target,
      this.cityTarget,
      this.stateTarget,
      this.zip_codeTarget,
      this.countryTarget,
      this.skipValidationTarget,
    ];

    if (e.target.checked) {
      checkTargets.forEach((elem) => {
        if (elem == this.countryTarget) {
          elem.value = "TBD";
        } else if (elem == this.stateTarget) {
          elem.value = "";
        } else if (elem == this.skipValidationTarget) {
          this.skipValidationTarget.checked = true;
        } else {
          elem.value = "tbd";
        }
      });
    } else {
      checkTargets.forEach((elem) => (elem == this.skipValidationTarget ? (elem.checked = false) : (elem.value = "")));
    }
  }

  checkSkipValidation() {
    this.skipValidationTarget.checked = true;
  }

  updateSubmitAddress() {
    this.submitAddressBtnTarget.value = "Add";
    this.submitAddressBtnTarget.disabled = false;
    this.skipValidationTarget.checked = true;
  }

  connect() {
    this.checkInternational(this.countryTarget.value);
  }
}
