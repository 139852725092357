import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["orders", "pagination"];

  initialize() {
    let options = {
      rootMargin: "0px",
    };

    this.intersectionObserver = new IntersectionObserver((orders) => this.processIntersectionorders(orders), options);
  }

  connect() {
    this.intersectionObserver.observe(this.paginationTarget);
    $(".sk-chasing-dots").addClass("d-none");
    window.addEventListener("scroll", function () {
      this.scrollY >= 100 ? $(".back-to-top").show() : $(".back-to-top").hide();
    });
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.paginationTarget);
  }

  processIntersectionorders(orders) {
    orders.forEach((order) => {
      if (order.isIntersecting) {
        this.loadMore();
        $(".back-to-top").removeClass("d-none");
      }
    });
  }

  loadMore() {
    if ($(".pagination li:nth-last-child(2)").hasClass("active")) {
      $(".sk-chasing-dots").addClass("d-none");
    }
    let next_page = this.paginationTarget.querySelector("a[rel='next']");
    if (next_page == null) {
      return;
    }
    let url = next_page.href;

    Rails.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      success: (data) => {
        this.ordersTarget.insertAdjacentHTML("beforeend", data.orders);
        this.paginationTarget.innerHTML = data.pagination;
      },
    });
    $(".sk-chasing-dots").removeClass("d-none");
  }

  backToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    $(".back-to-top").hide();
  }
}
