import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tooltip"];

  connect() {
    if (this.hasTooltipTarget) {
      $(this.tooltipTarget).tooltip();
    }
  }
}
