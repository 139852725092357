import { Controller } from "stimulus";

export default class extends Controller {
  // this is a child component to checkout cart controller. When the cart totals are loaded in triggers the checkAllQty function
  static targets = ["updateQty", "qtyRemaining", "allocatedQty", "totalQty", "itemQty"];

  addQty(e) {
    e.preventDefault();
    e.stopPropagation();

    const intValue = parseInt(this.updateQtyTarget.value);
    const addVal = 1 + intValue;
    const remainingVal = parseInt(this.qtyRemainingTarget.value);
    if (remainingVal == 0) {
      return;
    }

    this.qtyRemainingTarget.value = remainingVal - 1;
    this.updateQtyTarget.value = addVal;
    this.showHideSubmitBtn(this.qtyRemainingTarget.value);
  }

  subtractQty(e) {
    e.preventDefault();
    e.stopPropagation();

    const intValue = parseInt(this.updateQtyTarget.value);
    if (intValue == 0) {
      return;
    }
    const subtractVal = -1 + intValue;
    this.updateQtyTarget.value = subtractVal;
    const remainingVal = parseInt(this.qtyRemainingTarget.value);
    this.qtyRemainingTarget.value = remainingVal + 1;

    this.showHideSubmitBtn(this.qtyRemainingTarget.value);
  }

  inputQty() {
    const updQty = parseInt(this.updateQtyTarget.value);
    const itemQty = parseInt(this.itemQtyTarget.value);

    if (itemQty) {
      const total = itemQty - updQty + (parseInt(this.totalQtyTarget.value) - parseInt(this.allocatedQtyTarget.value));
      this.qtyRemainingTarget.value = total;
    } else if (updQty) {
      const total = parseInt(this.totalQtyTarget.value) - parseInt(this.allocatedQtyTarget.value) - updQty;
      this.qtyRemainingTarget.value = total;
    } else {
      const total = parseInt(this.totalQtyTarget.value) - parseInt(this.allocatedQtyTarget.value);
      this.qtyRemainingTarget.value = total;
    }

    this.showHideSubmitBtn(this.qtyRemainingTarget.value, updQty);
  }

  connect() {
    this.qtyRemainingTarget.value = parseInt(this.totalQtyTarget.value) - parseInt(this.allocatedQtyTarget.value);
  }

  showHideSubmitBtn(remainingQty, updQty) {
    // if on update the remaining value is > -1, show submit button
    if (remainingQty < 0 || updQty < 0) {
      // triggers an event in the parent
      const event = new Event("hide-submit", { bubbles: true });
      this.element.dispatchEvent(event);
    } else {
      const event = new Event("show-submit", { bubbles: true });
      this.element.dispatchEvent(event);
    }
  }
}
