import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["bundleIds"];

  update(e) {
    const bundleId = e.target.value;
    const bundleStr = this.bundleIdsTarget.value;
    const bundleArr = !bundleStr ? [] : bundleStr.split(",");

    if (bundleArr.includes(bundleId)) {
      const index = bundleArr.findIndex((elem) => elem === bundleId);
      if (index != -1) bundleArr.splice(index, 1);
    } else {
      bundleArr.push(bundleId);
    }

    this.bundleIdsTarget.value = bundleArr.join();
  }
}
