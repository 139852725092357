import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal"];

  connect() {
    const open = this.modalTarget.dataset.trigger;
    this.bundleModal = null;
    if (open === "true") {
      if (this.bundleModal == null) {
        this.bundleModal = new bootstrap.Modal("#editBundleModal", { keyboard: false });
      }
      this.bundleModal.show();
    }
  }
}
