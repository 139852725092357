import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "status",
    "getShipping",
    "addAddress",
    "enterAddress",
    "shipperNumberOption",
    "shippingGif",
    "shipperNumberSubmit",
    "showAddresses",
    "displayShipping",
    "noShipperNumber",
    "showShipmentsBtn",
    "addressSelect",
    "addressSelectContainer",
    "shippingBorder",
  ];

  static values = {
    showMethods: String,
  };

  connect() {
    if (this.shipModal) {
      this.shipModal.hide();
      this.shipModal = null;
    }
  }

  newAddress() {
    this.enterAddressTarget.classList.remove("d-none");
    this.addressSelectTarget.classList.add("d-none");

    if (this.hasAddAddressTarget) {
      this.addAddressTarget.classList.add("d-none");
    }
    if (this.hasGetShippingTarget) {
      this.getShippingTarget.classList.add("d-none");
    }
    if (this.hasShowShipmentsBtnTarget) {
      this.showShipmentsBtnTarget.classList.add("d-none");
    }
  }

  newShipment() {
    this.addressSelectContainerTarget.classList.remove("d-none");
    if (!this.hasAddressSelectTarget) {
      this.enterAddressTarget.classList.remove("d-none");
    }

    this.addAddressTarget.classList.add("d-none");

    if (this.hasGetShippingTarget) {
      this.getShippingTarget.classList.add("d-none");
    }
    if (this.hasShowShipmentsBtnTarget) {
      this.showShipmentsBtnTarget.classList.add("d-none");
    }
  }

  hideNewAddress() {
    this.enterAddressTarget.classList.add("d-none");
    if (this.hasAddressSelectTarget) {
      this.addressSelectTarget.classList.remove("d-none");
    } else {
      this.addAddressTarget.classList.remove("d-none");
      this.addressSelectContainerTarget.classList.add("d-none");

      if (this.hasGetShippingTarget) {
        this.getShippingTarget.classList.remove("d-none");
      }
      if (this.hasShowShipmentsBtnTarget) {
        this.showShipmentsBtnTarget.classList.remove("d-none");
      }
    }
  }

  exitAddress() {
    this.addressSelectContainerTarget.classList.add("d-none");

    if (this.hasAddAddressTarget) {
      this.addAddressTarget.classList.remove("d-none");
    }
    if (this.hasGetShippingTarget) {
      this.getShippingTarget.classList.remove("d-none");
    }
    if (this.hasShowShipmentsBtnTarget) {
      this.showShipmentsBtnTarget.classList.remove("d-none");
    }
  }

  getShipping(e) {
    const shippingWarningDiv = document.getElementById("shipping-limit-warning");

    if (shippingWarningDiv) {
      shippingWarningDiv.remove();
    }

    this.getShippingTarget.classList.add("d-none");
    if (this.hasAddAddressTarget) {
      this.addAddressTarget.classList.add("d-none");
    }
    this.showAddressesTarget.classList.add("d-none");
    // this.hideAddressesTarget.classList.remove("d-none");
    this.shippingBorderTarget.classList.remove("mt-4");

    if (this.hasShipperNumberOptionTarget) {
      e.preventDefault();
      e.stopPropagation();
      if (this.shipModal) {
        this.shipModal.hide();
        this.shipModal = null;
      }
      this.shipModal = new bootstrap.Modal("#distributorShipperNumber", { backdrop: "static", keyboard: false });
      this.shipModal.show();
    } else {
      this.shippingGifTarget.classList.remove("d-none");
    }
  }

  // Need to clean up to use stimulus fields
  validateShipperNumber(carrier, shipNum) {
    if (carrier == "UPS" && (shipNum.length != 6 || !/^[a-zA-Z0-9]+$/.test(shipNum))) {
      $("#ship_detail_alert").prop("hidden", false);
      $("#ship_detail_alert").append(`<p class="mb-0"> A valid UPS shipping acct number is 6 digits and contains only letters or numbers</p>`);
      return false;
    } else if (carrier == "FedEx" && (shipNum.length != 9 || !/^[0-9]+$/.test(shipNum))) {
      $("#ship_detail_alert").prop("hidden", false);
      $("#ship_detail_alert").append(`<p class="mb-0"> A valid FedEx shipping acct number is 9 digits and contains only numbers</p>`);
      return false;
    } else {
      $("#use_shipper_number_btn").prop("disabled", false);
      return true;
    }
  }

  validateShipNumSubmit() {
    $("#ship_detail_alert").prop("hidden", true);
    $("#ship_detail_alert p").remove();

    const carrier = $("#carrier").val();
    const shpNum = $("#use_shipper_number_field").val();

    return this.validateShipperNumber(carrier, shpNum);
  }

  // Called from: _distributor_shipper_modal
  shipperNumSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const isValid = this.validateShipNumSubmit();
    if (!isValid) {
      $("#use_shipper_number_btn").prop("disabled", true);
    } else {
      this.shipperNumberSubmitTarget.click();
      this.shipModal.hide();
      $("#distributorShipperNumber").hide();
      $("body").removeClass("modal-open");
      $("body").css("padding-right", "0px");
      $(".modal-backdrop").remove();
      $(this.shippingGifTarget).removeClass("d-none");
    }
  }

  // Called from: _distributor_shipper_modal
  noShipperNumberSubmit(e) {
    this.shipModal.hide();
    $("#distributorShipperNumber").hide();
    $("body").removeClass("modal-open");
    $("body").css("padding-right", "0px");
    $(".modal-backdrop").remove();
    $(this.shippingGifTarget).removeClass("d-none");
    this.noShipperNumberTarget.value = true;
  }

  editAddress() {
    if (this.hasAddAddressTarget) {
      this.addAddressTarget.classList.add("d-none");
    }

    if (this.hasGetShippingTarget) {
      this.getShippingTarget.classList.add("d-none");
    }
    
    if (this.hasShowShipmentsBtnTarget) {
      this.showShipmentsBtnTarget.classList.add("d-none");
    }
  }

  hideAddress() {
    if (this.addressSelectContainerTarget.classList.contains("d-none")) {
      if (this.hasGetShippingTarget) {
        this.getShippingTarget.classList.remove("d-none");
      } else if (this.hasShowShipmentsBtnTarget) {
        this.showShipmentsBtnTarget.classList.remove("d-none");
      }

      if (this.hasAddAddressTarget) {
        this.addAddressTarget.classList.remove("d-none");
      }
    }
  }

  // updateAddress() {
  //   this.hideAddressesTarget.classList.add("d-none");
  //   this.showAddressesTarget.classList.remove("d-none");
  //   this.displayShippingTarget.classList.add("d-none");
  //   this.shippingBorderTarget.classList.add("mt-4");

  //   if (this.hasAddAddressTarget) {
  //     this.addAddressTarget.classList.remove("d-none");
  //   }
  //   if (this.hasShowShipmentsBtnTarget) {
  //     this.showShipmentsBtnTarget.classList.remove("d-none");
  //   }
  // }

  showAddressForm() {
    this.showAddressesTarget.classList.remove("d-none");
    this.displayShippingTarget.classList.add("d-none");
    this.shippingBorderTarget.classList.remove("d-none");
    if (this.hasAddAddressTarget) {
      this.addAddressTarget.classList.remove("d-none");
    }
    if (this.showMethodsValue == "true") {
      this.showShipmentsBtnTarget.classList.remove("d-none");
    } else {
      this.showShipmentsBtnTarget.classList.add("d-none");
    }
  }

  showShipments() {
    // this.hideAddressesTarget.classList.remove("d-none");
    this.showAddressesTarget.classList.add("d-none");
    this.displayShippingTarget.classList.remove("d-none");
    this.shippingBorderTarget.classList.remove("mt-4");

    if (this.hasAddAddressTarget) {
      this.addAddressTarget.classList.add("d-none");
    }
    this.showShipmentsBtnTarget.classList.add("d-none");
  }
}
