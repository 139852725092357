import { Controller } from "stimulus";

export default class extends Controller {
  dropdown(e) {
    const dropdown_content = `${e.target.id}-content`;
    document.getElementById(dropdown_content).classList.toggle("project-show-dropdown");
    e.target.classList.toggle("rotate-180");
  }

  connect() {
    const project_arrows = document.getElementsByClassName("project-arrow");
    const order_arrows = document.getElementsByClassName("order-arrow");
    if (project_arrows.length == 1) {
      document.getElementById("project-down0-content").classList.toggle("project-show-dropdown");
      document.getElementById("project-down0").classList.toggle("rotate-180");
    }
    if (order_arrows.length == 1) {
      document.getElementById("order-down0-content").classList.toggle("project-show-dropdown");
      document.getElementById("order-down0").classList.toggle("rotate-180");
    }
  }
}
